import React,{useState,useEffect,useContext,createContext,Suspense, lazy} from 'react'
import {Route,Routes,Navigate,useNavigate,BrowserRouter,Switch,HashRouter} from 'react-router-dom'
import {UserContext} from './context/UserContext'
import axios from 'axios'
import ProtectedRoute from './screens/ProtectedRoute'
import SignUp from './screens/SignUp/SignUp'
import Login from './screens/Login/Login'
import ForgotPassword from './screens/ForgotPassword/ForgotPassword'

import Home from './screens/Protected/Home'

import HomePagePost from './screens/Protected/HomePagePost'
import Chat from './screens/Protected/Chat'
import Menu from './screens/Protected/Menu'
import MenuMe from './screens/Protected/MenuMe'
import MenuProfilePhoto from './screens/Protected/MenuProfilePhoto'
import Notifications from './screens/Protected/Notifications'
import WallOfFame from './screens/Protected/WallOfFame'
import Profile from './screens/Protected/Profile'
import ProfileNew from './screens/Protected/wallnew/ProfileNew'
import SearchResults from './screens/Protected/SearchResults'

import AccountActivation from './AccountActivation'
import PasswordRecovery from './PasswordRecovery'
import Challenges from './screens/Protected/Challenges'
import ChallengePost from './screens/Protected/ChallengePost'
import ChallengeExplainerVideo from './screens/Protected/ChallengeExplainerVideo'
import ChallengePostVideo from './screens/Protected/ChallengePostVideo'
import ChallengeProfileStory from './screens/Protected/ChallengeProfileStory'
import PostProfileStory from './screens/Protected/PostProfileStory'
import Account from './screens/Protected/Account'
import Privacy from './screens/Protected/Privacy'
import FriendsList from './screens/Protected/FriendsList'
import Blocks from './screens/Protected/Blocks'
import ChatRoom from './screens/Protected/ChatRoom'

import Home2 from './screens/Protected/wallnew/Home2'
import Home2Profile from './screens/Protected/wallnew/Home2Profile'
import HomePagePost2 from './screens/Protected/wallnew/HomePagePost2'
import ChallengePostVideo2 from './screens/Protected/wallnew/ChallengePostVideo2'
import Content from './screens/Protected/wallnew/Content'

import io from 'socket.io-client'
//import HomePagePost2 from './screens/Protected/wallnew/HomePagePost2'


//const socket=io(process.env.REACT_APP_SOCKET,{path: "/api/socket.io"})

export default function App() {


  const [isAuth,setIsAuth]=useState(false)
  const [userToken,setUserToken]=useState(null)
  const [apiToken,setApiToken]=useState(null)
  const [userID,setUserID]=useState(null)
  const [usrPhoto,setUsrPhoto]=useState('')
  const [firstName,setFirstName]=useState('')
  const [lastName,setLastName]=useState('')
  const [profileIsPublic,setProfileIsPublic]=useState(1)
  const [sportID,setSportID]=useState(0)
  const [langID,setLangID]=useState(0)
  const [usersWhoBlockedMe,setUsersWhoBlockedMe]=useState([])
  const [messageNotifications,setMessageNotifications]=useState(0)
  const [socket,setSocket]=useState(io(process.env.REACT_APP_SOCKET,{path: "/api/socket.io"},{transports: [ "websocket", "polling" ]}))
  const [generalNotifications,setGeneralNotifications]=useState(0)
  const [newVideo,setNewVideo]=useState('')
  const [newChallenge,setNewChallenge]=useState(false)
  const [itemToView,setItemToView]=useState(null)
  
  //const Home = lazy(() => import('./screens/Protected/Home'));

  useEffect(()=>{
    socket.on("chat-notification",ReceiverUUID=>{
      //alert(ReceiverUUID)
      loadUnreadMessages(ReceiverUUID)
    })
  },[socket])


  const loadUnreadMessages=(uuid)=>{
    axios.get(process.env.REACT_APP_API_SERVER+'messenger/messages/unread/'+uuid,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
      //console.log(res.data.numOfMessages)
      setMessageNotifications(res.data.numOfMessages)
    }).catch(err=>{
      console.log(err)
    })
  }

  const loadUnreadNotifications=(uuid)=>{
    axios.get(process.env.REACT_APP_API_SERVER+'notifications/unreadnotifications/'+uuid,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("token")
          }            
      })
    .then(res=>{
      //console.log(res.data.numOfNotifications)
      setGeneralNotifications(res.data.numOfNotifications)

      })
  }


  useEffect(()=>{
    if (localStorage.getItem('usrObj')){

      const usrObj=JSON.parse(localStorage.getItem('usrObj'))
      sessionStorage.setItem('token', usrObj.apiToken)

      // apiToken:res.data.token,
      // isAuth:true,
      // userID:res.data.uid,
      // usrPhoto:loadImage(res.data.uid),
      // firstName:res.data.FirstName,
      // lastName:res.data.LastName,
      // sportID:res.data.SportID,
      // langID:res.data.LangID,
      // profileIsPublic:res.data.ProfileIsPublic,
      // token:res.data.token

      setApiToken(usrObj.apiToken)
      setIsAuth(true)
      setUserID(usrObj.userID)
      setUsrPhoto(usrObj.usrPhoto)
      setFirstName(usrObj.firstName)
      setLastName(usrObj.lastName)
      setSportID(usrObj.sportID)
      setLangID(usrObj.langID)
      setProfileIsPublic(usrObj.profileIsPublic) 
      
 

    }
  },[])

  const loadUsersWhoBlockedMe=async (uid)=>{
   // setIsLoading(true)
   return new Promise((resolve,reject)=>{
        axios.get(process.env.REACT_APP_API_SERVER+'block/blockeduser/'+uid,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("token")
              }            
          })
        .then(res=>{


          resolve(res.data.friends)
        // setIsLoading(false)
        }).catch(err=>{
          // setIsLoading(false)
          reject({err})
        }) 
      })
   }




  return (
    <UserContext.Provider value={{
        isAuth,setIsAuth,
        apiToken,setApiToken,
        userID,setUserID,
        usrPhoto,setUsrPhoto,
        firstName,setFirstName,
        lastName,setLastName,
        sportID,setSportID,
        langID,setLangID,
        profileIsPublic,setProfileIsPublic,
        usersWhoBlockedMe,setUsersWhoBlockedMe,
        loadUsersWhoBlockedMe,
        socket,setSocket,
        messageNotifications,setMessageNotifications,
        loadUnreadMessages,
        loadUnreadNotifications,
        generalNotifications,setGeneralNotifications,
        newVideo,setNewVideo,
        newChallenge,setNewChallenge,
        itemToView,setItemToView
        
      }}>

        
    <HashRouter basename={'/'}>

    <Routes>
        <Route exact path="/" element={<Login activate={0}/>}/>
        <Route exact path="/signup" element={<SignUp/>}/>
        <Route exact path="/login" element={<Login activate={0}/>}/>
        <Route exact path="/login/activate" element={<Login activate={1}/>}/>
        <Route exact path="/forgotpwd" element={<ForgotPassword/>}/>
        <Route exact path="/accountactivation/:uuid" element={<AccountActivation/>}/>
        <Route exact path="/resetpassword/:uuid" element={<PasswordRecovery/>}/>

        {/* <Route exact path="/home" element={<Home/>}/> */}

        <Route exact path="/home" element={<ProtectedRoute Component={Home2} />}/>
        <Route exact path="/home2" element={<ProtectedRoute Component={Home}/>}/>
        <Route exact path="/home2profile/:uid/:itemUUID" element={<ProtectedRoute Component={Home2Profile}/>}/>
        <Route exact path="/profile/:uid" element={<ProtectedRoute Component={ProfileNew}/>}/>
        <Route exact path="/walloffame" element={<ProtectedRoute Component={WallOfFame}/>}/>
        <Route exact path="/challenges" element={<ProtectedRoute Component={Challenges}/>}/>
        <Route exact path="/chat" element={<ProtectedRoute Component={Chat}/>}/>
        <Route exact path="/menu" element={<ProtectedRoute Component={Menu}/>}/>
        <Route exact path="/menume" element={<ProtectedRoute Component={MenuMe}/>}/>
        <Route exact path="/account" element={<ProtectedRoute Component={Account}/>}/>
        <Route exact path="/menuprofilephoto" element={<ProtectedRoute Component={MenuProfilePhoto}/>}/>
        <Route exact path="/notifications" element={<ProtectedRoute Component={Notifications}/>}/>
        <Route exact path="/homepagepost" element={<ProtectedRoute Component={HomePagePost2} nofooter={1}/>} />
        <Route exact path="/challengepost/:id/:langid" element={<ProtectedRoute Component={ChallengePost}/>}/>
        <Route exact path="/challengepostvideo/:id/:langid" element={<ProtectedRoute Component={ChallengePostVideo2} nofooter={1}/>}/>
        <Route exact path="/challengeexplainervideo/:id/:langid" element={<ProtectedRoute Component={ChallengeExplainerVideo} />}/>
        <Route exact path="/challengeprofilestory/:id/:langid/:uid" element={<ProtectedRoute Component={ChallengeProfileStory}/>}/>
        <Route exact path="/postprofilestory/:id/:langid/:uid" element={<ProtectedRoute Component={PostProfileStory}/>}/>
        <Route exact path="/content" element={<ProtectedRoute Component={Content}/>}/>
        <Route exact path="/searchresults/:s" element={<ProtectedRoute Component={SearchResults}/>}/>
        <Route exact path="/privacy" element={<ProtectedRoute Component={Privacy}/>}/>
        <Route exact path="/friendslist/:userUUID" element={<ProtectedRoute Component={FriendsList}/>}/>
        <Route exact path="/blocks" element={<ProtectedRoute Component={Blocks}/>}/>
        <Route exact path="/chatroom/:roomUUID" element={<ProtectedRoute Component={ChatRoom} nofooter={1}/>}/>


      </Routes>

    </HashRouter>
    </UserContext.Provider>
  )
}
