import React,{useState,useEffect,useContext,createContext} from 'react'
import {Navigate,useNavigate} from 'react-router-dom'
import '../../css/style.css'
import Logo from '../../css/images/logo.png'
import bgImage from '../../css/images/MainBg.png'
import axios from 'axios'
import moment from 'moment'

export default function SignUp() {

  const [sports,setSports]=useState([])
  const [countries,setCountries]=useState([])
  const [isSaving,setIsSaving]=useState(false)
  const [regObj,setRegObj]=useState({
    Email:'',
    Password:'',
    OtherPassword:'',
    MobilePhone:'',
    NickName:'',
    DateOfBirth:'',
    CountryID:'',
    SportID:'',
    FirstName:'',
    LastName:''
  })
  const [hasErr,setHasErr]=useState(false)
  const [errMsg,setErrMsg]=useState('')
  const [maxRegDate,setMaxRegDate]=useState(null)
  const [iAgree,setIAgree]=useState(false)
  const [parentalContolNeeded,setParentalContolNeeded]=useState(false)

  const navigate=useNavigate ()

  useEffect(()=>{
    loadSports()
    loadCountries()


    let present = new Date()
    let presentDay= present.toISOString().split('T')[0]
    setMaxRegDate(moment(presentDay).subtract(13,'years').format('YYYY-MM-DD'))

  },[])

 const password_show_hide=()=> {
    var x = document.getElementById("floatingPassword");
    var show_eye = document.getElementById("show_eye");
    var hide_eye = document.getElementById("hide_eye");
    hide_eye.classList.remove("d-none");
    if (x.type === "password") {
      x.type = "text";
      show_eye.style.display = "none";
      hide_eye.style.display = "block";
    } else {
      x.type = "password";
      show_eye.style.display = "block";
      hide_eye.style.display = "none";
    }

}


function password_show_hide2() {
  var x = document.getElementById("floatingPassword2");
  var show_eye = document.getElementById("show_eye2");
  var hide_eye = document.getElementById("hide_eye2");
  hide_eye.classList.remove("d-none");
  if (x.type === "password") {
    x.type = "text";
    show_eye.style.display = "none";
    hide_eye.style.display = "block";
  } else {
    x.type = "password";
    show_eye.style.display = "block";
    hide_eye.style.display = "none";
  }
}


const loadSports=()=>{
  axios.get(process.env.REACT_APP_API_SERVER+'sports')
    .then(res=>{

        if (res.data.err==0){
         setSports(res.data.results)
        // console.log(res.data.results)
        }else{
            alert(res.data.msg)
        }

    }).catch(err=>{
      alert("An error has occured, please try again")
    })         
}


const loadCountries=()=>{
  axios.get(process.env.REACT_APP_API_SERVER+'countries')
    .then(res=>{

        if (res.data.err==0){
         setCountries(res.data.results)
         //console.log(res.data.results)
        }else{
            alert(res.data.msg)
        }

    }).catch(err=>{
      alert("An error has occured, please try again")
    })         
}


const sportsList=sports.map(item=>{
  return(
    <option key={item.SportUUID} value={item.SportID}>{item.SportName}</option>
  )
})


const countriesList=countries.map(item=>{
  return(
    <option key={item.UUID} value={item.CountryID}>{item.Country}</option>
  )
})

const register=(e)=>{
  e.preventDefault()
 

  if (parentalContolNeeded && (regObj.Email==regObj.ParentEmail)){
        setIsSaving(false)
        setHasErr(true)
        setErrMsg("Your Email and custodian's Email must be different")    
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Optional, for smooth scrolling
        }); 
    
  }else{
      setIsSaving(true)
     // if (regObj.Password==regObj.OtherPassword){
        axios.post(process.env.REACT_APP_API_SERVER+'register',regObj)
        .then(async res=>{
            if (res.data.err==0){
                setHasErr(false)
                setErrMsg('')
                localStorage.setItem('SportUUID', sports.filter(item=>item.SportID==regObj.SportID)[0].UUID);
                navigate('/login/activate')
            }else{
              //alert(res.data.msg)
              setHasErr(true)
              setErrMsg(res.data.msg)
              window.scrollTo({
                top: 0,
                behavior: 'smooth' // Optional, for smooth scrolling
              }); 
            }
            setIsSaving(false)
          }).catch(err=>{
            setIsSaving(false)
            setHasErr(true)
            setErrMsg('An error has occured, please try again')
            window.scrollTo({
              top: 0,
              behavior: 'smooth' // Optional, for smooth scrolling
            }); 
            //alert("An error has occured, please try again")
        })
      // }else{
      //   setIsSaving(false)
      //   setHasErr(true)
      //   setErrMsg('Passwords do not match.')   
      //   window.scrollTo({
      //     top: 0,
      //     behavior: 'smooth' // Optional, for smooth scrolling
      //   }); 
      // }
  }
 
  
}

const dateOfBirth=(d)=>{
 //console.log(d)
  var xRegObj={...regObj}
  
  xRegObj.DateOfBirth=d
  //console.log(xRegObj)
  const today=new Date()
  const y=d.split('-')[0]
  const yyyy=today.getFullYear()
  const age=yyyy-y
  if (age<17){
    setParentalContolNeeded(true)
  }else{
    setParentalContolNeeded(false)
    //setRegObj({...regObj,ParentEmail:''})
    xRegObj.ParentEmail=''
  }
 // console.log(xRegObj)
  setRegObj({...xRegObj})
}

  return (
    <div className="container container-mobile">
    
    
    <div className="div-wrap">
      <div
        className="box-image"
        style={{backgroundImage:'url('+bgImage+')'}}
       // style={{background-image: url(images/Signup3.jpg)}}
      >
        <div className="logo"><img src={Logo} alt="logo" /></div>
      </div>
      <div className="form-div">
        <div
          className="form-buttons w-100 justify-content-space-between mb-3 border-grey"
        >
          <button type="button" className="btn w-100 btn-grey" onClick={()=>navigate('/login')}>
            Login
          </button>
          <button type="button" className="btn w-100 btn-orange">Sign Up</button>
        </div>
        {!isSaving &&

        <form onSubmit={register}>

          {hasErr &&
          <div className="alert alert-danger" role="alert">
          {errMsg}
          </div>                  
          
          }
        <div className="form-floating mb-3">
            <input
              maxLength={25}
              type="text"
              className="form-control"
              id="floatingInput21"
              placeholder="First Name"
              required
              value={regObj.FirstName}
              onChange={(e)=>setRegObj({...regObj,FirstName:e.target.value})}
            />
            <label for="floatingInput21">First Name</label>
          </div>
          <div className="form-floating mb-3">
            <input
              maxLength={25}
              type="text"
              className="form-control"
              id="floatingInput22"
              placeholder="Last Name"
              required
              value={regObj.LastName}
              onChange={(e)=>setRegObj({...regObj,LastName:e.target.value})}
            />
            <label for="floatingInput22">Last Name</label>
          </div>
          <div className="form-floating mb-3">
            <input
              maxLength={64}
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              required
              value={regObj.Email.toLowerCase()}
              onChange={(e)=>setRegObj({...regObj,Email:e.target.value})}
            />
            <label for="floatingInput">Email address</label>
          </div>
          <div className="input-group d-flex mb-3">
            <div className="form-floating">
              <input
                minLength={6}
                maxLength={10}
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                required
                value={regObj.Password}
                onChange={(e)=>setRegObj({...regObj,Password:e.target.value})}
              />
              <label for="floatingPassword">Password</label>
            </div>
            <div
              className="input-group-append d-flex align-items-center password-box"
            >
              <span
                className="input-group-text password-eye"
                onClick={password_show_hide}
              >
                <i className="bi bi-eye" id="show_eye"></i>
                <i className="bi bi-eye-slash d-none" id="hide_eye"></i>
              </span>
            </div>
          </div>
          {/* <div className="input-group d-flex mb-3">
            <div className="form-floating">
              <input
                maxLength={10}
                type="password"
                className="form-control"
                id="floatingPassword2"
                placeholder="Repeat Password"
                required
                value={regObj.OtherPassword}
                onChange={(e)=>setRegObj({...regObj,OtherPassword:e.target.value})}
              />
              <label for="floatingPassword2">Repeat Password</label>
            </div>
            <div
              className="input-group-append d-flex align-items-center password-box"
            >
              <span
                className="input-group-text password-eye"
                onClick={password_show_hide2}
              >
                <i className="bi bi-eye" id="show_eye2"></i>
                <i className="bi bi-eye-slash d-none" id="hide_eye2"></i>
              </span>
            </div>
          </div> */}
          {/* <div className="form-floating mb-3">
            <input
              maxLength={20}
              type="tel"
              className="form-control"
              id="floatingInput2"
              placeholder="Mobile phone"
              required
              value={regObj.MobilePhone}
              onChange={(e)=>setRegObj({...regObj,MobilePhone:e.target.value})}
            />
            <label for="floatingInput2">Mobile Phone</label>
          </div> */}


          <div className="form-floating mb-3">
            <input
              type="date"
              max={maxRegDate}
              className="form-control"
              id="floatingInput4"
              placeholder="DD/MM/YY"
              value={regObj.DateOfBirth}
              onChange={(e)=>dateOfBirth(e.target.value)}
            />
            <label for="floatingInput4">Date of Birth</label>
          </div>

          {parentalContolNeeded &&
          
          <div className="form-floating mb-3" >
            <br/><br/>
            <input
              maxLength={64}
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              required
              value={regObj.ParentEmail}
              onChange={(e)=>setRegObj({...regObj,ParentEmail:e.target.value})}
            />
            <label for="floatingInput"><b>Custodian's Email address</b></label><br/>
            In order to follow rules around necessary security measures for your personal data, we need to know that your custodian agrees with you using our application.
            <br/>
            Typing the email address above means that you have their full consent to use 1,2,3 High App.   
            <br/><br/>
          </div>          
          
          }



          <select
            id="country"
            name="country"
            required
            className="form-select form-control mb-3"
            value={regObj.CountryID}
            onChange={(e)=>setRegObj({...regObj,CountryID:e.target.value})}
          >
            <option value=''>[Select Country]</option>
            {/* <option value="Country">
              Country<span style={{display:'inline', float:'none'}}>*</span>
            </option> */}
            {countriesList}
          </select>

          <div className="d-flex mb-3 pb-3 sport-div">
            <div className="sport-text">Sport:</div>
            <div className="w-100">
              <select
                className="form-select sport-select"
                aria-label="Select Sport"
                id="sportSelected"
                required
                value={regObj.SportID}
                onChange={(e)=>setRegObj({...regObj,SportID:e.target.value})}
              >
                <option value=''>[Select Sport]</option>
                {sportsList}
              </select>
            </div>
          </div>


          <div className="agree-terms2">
            <input type="checkbox" checked={iAgree} onChange={(e)=>setIAgree(e.target.checked)}/>&nbsp;
            By creating an account you agree to our <a href=" https://onetwothreehigh.com/terms/" target="_blank">Terms of Services</a> and 
            &nbsp;<a href="https://onetwothreehigh.com/privacy/" target="_blank">Privacy
            Policy</a>
          </div>


          <button disabled={!iAgree} type="submit" className="btn orange mt-3">Register</button>
        </form>
        }

        {isSaving &&
          <div className="alert alert-warning" role="alert">
            Saving, please wait...
        </div>        
        }
      </div>
    </div>
    {/* <div className="agree-terms">
      By creating an account you agree to our <a href=" https://onetwothreehigh.com/terms/" target="_blank">Terms of Services</a> and 
      &nbsp;<a href="https://onetwothreehigh.com/privacy/" target="_blank">Privacy
      Policy</a>
    </div> */}
    
  </div>
 
  )
}
